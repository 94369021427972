<template>
  <div class="load-review-container">
    <my-nav-bar
      title="装车复核"
      left-text="返回"
      left-arrow
    />
    <div class="app-main-container">
      <van-empty v-show="showEmpty" description="暂无数据" />
      <van-cell
        v-for="(distributor, index) in distributors"
        :key="index"
        center
        :title="'配送员：' + distributor.name"
        :value="distributor.client_count > 0 ? distributor.client_count + '个客户待装车复核' : ''"
        :is-link="!distributor.load_review_id || distributor.load_review_id === $store.state.userInfo.id"
        @click="toListView(distributor)"
      >
        <template v-if="distributor.load_review_id" #label>
          <span>{{ `复核人：${distributor.load_review_name}` }}</span>
        </template>
      </van-cell>
    </div>
  </div>
</template>

<script>
import loadReviewApi from '@/api/load_review'
import myNavBar from '../../components/my-nav-bar.vue'
export default {
  name: 'LoadReviewIndex',
  components: { myNavBar },
  data() {
    return {
      distributors: [],
      showEmpty: false
    }
  },
  created() {
    this.getDistributors()
  },
  methods: {
    getDistributors() {
      this.beginLoad()
      loadReviewApi.distributor().then(res => {
        this.endLoad()
        this.distributors = res.data
        this.showEmpty = this.distributors.length === 0
      })
    },
    toListView(distributor) {
      if (distributor.load_review_id && distributor.load_review_id !== this.$store.state.userInfo.id) { return }
      this.$router.push({
        path: '/load-review-detail',
        query: {
          delivery_id: distributor.delivery_id,
          delivery_name: distributor.name
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
